
import Vue from 'vue';

export default Vue.extend({
  name: 'ViewError',

  props: {
    error: {
      type: [String, Number],
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      errorClass: '',

      messageData: '',

      titleData: '',
    };
  },

  mounted() {
    if (!this.message && !this.title) {
      this.messageData = `error_${this.error}`;
      this.errorClass = `error-${this.error}`;

      switch (this.error.toString()) {
        case '404': default:
          this.titleData = 'page_not_found';
          break;
      }
    }
  },
});
